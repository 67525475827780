import { useSearchParams } from 'react-router-dom';

const isEnumType = <T extends object>(value: any, enumType: T): value is T[keyof T] => {
  return Object.values(enumType).includes(value);
};

type ParamConfig<T> = {
  param: string;
  enumType: T;
};

const useQueryParams = <T extends Record<string, ParamConfig<any>>>(
  paramConfigs?: T
): (T extends undefined ? {} : { [K in keyof T]: T[K]['enumType'] | undefined }) & {
  page: number;
  onLoadMore: () => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') ?? '1';

  const paramValues = paramConfigs
    ? Object.keys(paramConfigs).reduce((acc, key) => {
        const { param, enumType } = paramConfigs[key as keyof T];
        const paramValue = searchParams.get(param);
        acc[key as keyof T] = isEnumType(paramValue, enumType)
          ? (paramValue as T[keyof T]['enumType'])
          : undefined;
        return acc;
      }, {} as { [K in keyof T]: T[K]['enumType'] | undefined })
    : {};

  const onLoadMore = () => {
    let queryParams: any = [];
    searchParams.forEach((value, key) => {
      queryParams.push([key, value]);
    });
    queryParams = queryParams.filter(([key]) => key !== 'page');
    queryParams.push(['page', (parseInt(page) + 1).toString()]);
    setSearchParams(queryParams);
  };

  return { ...paramValues, page: parseInt(page), onLoadMore } as any;
};

export default useQueryParams;
