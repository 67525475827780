import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import bankIcon from 'media/icons/bank.svg';
import bellIcon from 'media/icons/bell.svg';
import companyIcon from 'media/icons/company.svg';
import informationIcon from 'media/icons/information.svg';

const USER_QUERY = gql(`
  query getUser($userId: ID!) {
    user(id: $userId) {
      id
      name
    }
  }
`);

const UserPage: FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>() as { userId: string };

  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: {
      userId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const user = data.user;

    const tabs = [
      {
        key: 'information',
        label: 'Information',
        description: 'Personal details',
        icon: informationIcon,
        route: `/users/${userId}`,
        exact: true,
      },
      {
        key: 'notification-preferences',
        label: 'Notification Preferences',
        description: 'How you want to be notified',
        icon: bellIcon,
        route: `/users/${userId}/notification-preferences`,
      },
      {
        key: 'investment-entities',
        label: 'Investment Entities',
        description: 'Entities you use to invest',
        icon: bankIcon,
        route: `/users/${userId}/investment-entities`,
      },
      {
        key: 'wishlisted',
        label: 'Wishlisted',
        description: 'Companies wishlisted by this user',
        icon: companyIcon,
        route: `/users/${userId}/wishlisted`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={user.name}
          subTitle="User"
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  }

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default UserPage;
