import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundManagerRoleEnumType } from '__generated__/graphql';
import { FC, useRef, useState } from 'react';
import SearchUsers from 'routes/Syndicate/Leads/SearchUsers';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const ADD_USER_TO_FUND_MUTATION = gql(`
  mutation AddManagerToFund(
    $fundId: String!
    $userId: String!
    $role: FundManagerRoleEnumType!
  ) {
    addManagerToFund(fundId: $fundId, userId: $userId, role: $role) {
      id
      user {
        id
        name
        email
      }
      role
      createdAt
    }
  }
`);

const AddManagerToFundDialogButton: FC<{ fundId: string }> = ({ fundId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [addManagerToFund, { loading, error }] = useMutation(ADD_USER_TO_FUND_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      const { addManagerToFund } = data;
      cache.modify({
        id: `FundType:${fundId}`,
        fields: {
          fundManagers(existingManagersRef = []) {
            const newManagerRef = cache.writeFragment({
              data: { ...addManagerToFund },
              fragment: gql(`
                fragment NewManagerToFund on FundManagerType {
                  id
                  user {
                    id
                    name
                    email
                  }
                  createdAt
                }
              `),
            });

            return [...existingManagersRef, newManagerRef];
          },
        },
      });
    },
    onCompleted() {
      toggleDialog(false);
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add Manager</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add a manager</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              addManagerToFund({
                variables: {
                  fundId,
                  userId: data.user.id,
                  role: data.role,
                },
              });
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="role"
              label="Role"
              defaultValue={'ADMIN'}
              options={Object.entries(FundManagerRoleEnumType).map(([key, value]) => ({
                label: key,
                value: value,
              }))}
            />
            <FormInput
              type="custom"
              fieldName="user"
              label="User"
              defaultValue={''}
              customInput={SearchUsers}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AddManagerToFundDialogButton;
