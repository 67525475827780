import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_USER_MUTATION = gql(`
  mutation UpdateUserStatus($id: ID!, $status: UserStatusEnumType!) {
    updateUserStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdateUserStatus: FC<{
  id: string;
  status: UserStatusEnumType;
}> = ({ id, status }) => {
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUser({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
      title="Status"
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          {
            value: UserStatusEnumType.Active,
            label: 'Active',
            color: 'bg-green-500',
          },
          {
            value: UserStatusEnumType.Inactive,
            label: 'Inactive',
            color: 'bg-red-500',
          },
          {
            value: UserStatusEnumType.UnderReview,
            label: 'Under Review',
            color: 'bg-yellow-500',
          },
          {
            value: UserStatusEnumType.RuvReview,
            label: 'RUV Review',
            color: 'bg-yellow-500',
          },
          {
            value: UserStatusEnumType.RuvActive,
            label: 'RUV Active',
            color: 'bg-green-500',
          },
          {
            value: UserStatusEnumType.PendingPlatformActivation,
            label: 'Pending Platform Activation',
            color: 'bg-yellow-500',
          },
          {
            value: UserStatusEnumType.PendingEmailVerification,
            label: 'Pending Email Verification',
            color: 'bg-yellow-500',
          },
          {
            value: UserStatusEnumType.PendingOnboarding,
            label: 'Pending Onboarding',
            color: 'bg-yellow-500',
          },
          {
            value: UserStatusEnumType.Suspended,
            label: 'Suspended',
            color: 'bg-red-500',
          },
          {
            value: UserStatusEnumType.Inactive,
            label: 'Inactive',
            color: 'bg-red-500',
          },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateUserStatus;
