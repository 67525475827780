import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealExitStatusEnumType, DealExitTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';

import Button from 'primitives/Button';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import dealExitTypeToReadable from 'utils/enums/deal-exit-type-to-readable';

const UPDATE_DEAL_EXIT_MUTATION = gql(`
  mutation UpdateDealExit(
    $id: ID!,
    $type: DealExitTypeEnumType,
    $percentageOfShares: Float,
    $pricePerShare: Float,
    $exitedAt: String,
    $comments: String,
    $footnotes: String,
    $distributionExpenses: Float,
    $multipleOnInvestment: Float,
    $totalSaleProceeds: Float,
    $applyCostIndexation: Boolean
  ) {
    updateDealExit(
      id: $id,
      type: $type,
      percentageOfShares: $percentageOfShares,
      pricePerShare: $pricePerShare,
      exitedAt: $exitedAt,
      comments: $comments,
      footnotes: $footnotes,
      distributionExpenses: $distributionExpenses,
      multipleOnInvestment: $multipleOnInvestment,
      totalSaleProceeds: $totalSaleProceeds,
      applyCostIndexation: $applyCostIndexation
    ) {
      id
      type
      percentageOfShares
      pricePerShare
      status
      exitedAt
      createdAt
      comments
      footnotes
      distributionExpenses
      multipleOnInvestment
      totalSaleProceeds
      applyCostIndexation
    }
  }
`);

interface UpdateDealExitProps {
  dealExit: {
    id: string;
    type: string;
    percentageOfShares: number;
    pricePerShare: number;
    exitedAt: string;
    status: string;
    comments?: string | null;
    footnotes?: string | null;
    distributionExpenses?: number | null;
    multipleOnInvestment: number;
    totalSaleProceeds?: number | null;
    applyCostIndexation?: boolean | null;
  };
  deal: {
    id: string;
    schemeName?: string | null;
    companyInformation: {
      company: {
        name: string;
      };
    };
  };
}

const UpdateDealExit: FC<UpdateDealExitProps> = ({ dealExit, deal }) => {
  const [updateDealExit, { loading, error }] = useMutation(UPDATE_DEAL_EXIT_MUTATION);

  const handleSubmit = async (data: any) => {
    await updateDealExit({
      variables: {
        id: dealExit.id,
        type: data.type,
        percentageOfShares: parseFloat(data.percentageOfShares),
        pricePerShare: parseFloat(data.pricePerShare),
        exitedAt: data.exitedAt,
        comments: data.comments,
        footnotes: data.footnotes,
        distributionExpenses: data.distributionExpenses
          ? parseFloat(data.distributionExpenses)
          : null,
        multipleOnInvestment: parseFloat(data.multipleOnInvestment),
        totalSaleProceeds: data.totalSaleProceeds ? parseFloat(data.totalSaleProceeds) : null,
        applyCostIndexation: data.applyCostIndexation,
      },
    });
  };

  return (
    <FormPanelWithReadMode onSubmit={handleSubmit} loading={loading} error={error}>
      <Button variant="secondary" onClick={() => window.open(`/deals/${deal.id}`, '_blank')}>
        {deal.schemeName}
      </Button>
      <FormInput
        type="text"
        fieldName="company"
        label="Company"
        defaultValue={deal.companyInformation.company.name}
        editable={false}
      />
      <FormInput
        type="select"
        fieldName="type"
        label="Type"
        defaultValue={dealExit.type}
        options={Object.values(DealExitTypeEnumType).map(type => ({
          label: dealExitTypeToReadable(type).label,
          value: type,
        }))}
      />
      <FormInput
        type="number"
        fieldName="percentageOfShares"
        label="Percentage of Shares"
        defaultValue={dealExit.percentageOfShares}
        validators={{ required: true }}
      />
      <FormInput
        type="number"
        fieldName="pricePerShare"
        label="Price Per Share"
        helperText="Please enter the exact price per share, including all decimal places"
        defaultValue={dealExit.pricePerShare}
        validators={{ required: true }}
      />
      <FormInput
        type="date"
        fieldName="exitedAt"
        label="Exited At"
        defaultValue={dealExit.exitedAt}
        validators={{ required: true }}
      />
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={dealExit.status}
        options={[
          { label: 'Initiated', value: DealExitStatusEnumType.Initiated },
          { label: 'Finalised', value: DealExitStatusEnumType.Finalised },
        ]}
      />
      <FormInput
        type="text"
        fieldName="comments"
        label="Comments"
        defaultValue={dealExit.comments}
      />
      <FormInput
        type="text"
        fieldName="footnotes"
        label="Footnotes"
        defaultValue={dealExit.footnotes}
      />
      <FormInput
        type="number"
        fieldName="distributionExpenses"
        label="Distribution Expenses"
        defaultValue={dealExit.distributionExpenses}
      />
      <FormInput
        type="number"
        fieldName="multipleOnInvestment"
        label="Multiple on Investment"
        defaultValue={dealExit.multipleOnInvestment}
        validators={{ required: true }}
      />
      <FormInput
        type="currency"
        fieldName="totalSaleProceeds"
        label="Total Sale Proceeds"
        helperText="Total amount received from the sale"
        defaultValue={dealExit.totalSaleProceeds}
      />
      <FormInput
        type="switch"
        fieldName="applyCostIndexation"
        label="Apply Cost Indexation"
        defaultValue={dealExit.applyCostIndexation}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateDealExit;
