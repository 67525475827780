import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import fundingSourceToReadable from 'utils/enums/funding-source-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import ClosingSlideOver from './ClosingSlideOver';
import CreateClosingDialogButton from './CreateClosingDialogButton';

const CLOSINGS_QUERY = gql(`
  query fetchClosingsByDeal($dealId: ID!, $cursor: ID, $limit: Int, $filters: ClosingsFilterType) {
    deal(id: $dealId) {
      id
      closings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          amount
          status
          investmentEntity {
            id
            name
            user {
              id
              name
            }
          }
          fundingSource
          ownershipPercentage
          currentValue
          realizedValue
          alCarryPercentage
          gpCarryPercentage
          createdAt
          updatedAt
          sourceDealInviteLink {
            id
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      statistics {
        dealStatisticsBreakdownByClosingStatus {
          status
          numberOfClosings
        }
        dealStatisticsBreakdownByClosingFundingSource {
          fundingSource
          numberOfClosings
        }
      }
    }
  }
`);

const DealClosings: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [closingOnDisplay, setClosingOnDisplay] = useState('');

  const { loading, error, data, refetch, fetchMore, variables } = useQuery(CLOSINGS_QUERY, {
    variables: {
      limit: 50,
      dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;
  const closings = deal.closings.nodes;
  const pageInfo = deal.closings.pageInfo;
  const statistics = deal.statistics;

  const filters = [
    {
      type: 'statuses',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.dealStatisticsBreakdownByClosingStatus.map(
          ({ status, numberOfClosings }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfClosings})`,
            value: [status],
          })
        ),
      ],
      readable: statusEnumToReadable,
    },
    {
      type: 'fundingSource',
      label: 'Funding Source',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.dealStatisticsBreakdownByClosingFundingSource.map(
          ({ fundingSource, numberOfClosings }) => ({
            label: `${fundingSourceToReadable(fundingSource).label} (${numberOfClosings})`,
            value: fundingSource,
          })
        ),
      ],
      readable: fundingSourceToReadable,
    },
  ];

  return (
    <>
      <ClosingSlideOver
        open={!!closingOnDisplay}
        closingId={closingOnDisplay}
        onClose={() => setClosingOnDisplay('')}
      />
      <div>
        <CreateClosingDialogButton dealId={deal.id} />
      </div>
      <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
      <DataTable
        data={closings}
        searchFields={['investmentEntity.name', 'investmentEntity.user.name']}
        onClick={closing => setClosingOnDisplay(closing.id)}
        columns={[
          {
            label: 'Investor',
            fieldName: 'investmentEntity.user.name',
          },
          {
            label: 'Entity',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Funding Source',
            fieldName: 'fundingSource',
            type: 'FUNDING_SOURCE',
          },
          {
            label: 'Amount',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Current Value',
            fieldName: 'currentValue',
            type: 'CURRENCY',
          },
          {
            label: 'Realized Value',
            fieldName: 'realizedValue',
            type: 'CURRENCY',
          },
          {
            label: 'Ownership (%)',
            fieldName: 'ownershipPercentage',
            type: 'PERCENTAGE',
          },
          {
            label: 'AL Carry',
            fieldName: 'alCarryPercentage',
            type: 'PERCENTAGE',
          },
          {
            label: 'GP Carry',
            fieldName: 'gpCarryPercentage',
            type: 'PERCENTAGE',
          },
          {
            label: 'Closing Date',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Source deal invite link',
            fieldName: 'sourceDealInviteLink.id',
            type: 'STRING',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default DealClosings;
