import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_FUND_POST_MUTATION = gql(`
  mutation UpdateFundPostOnDeal($id: ID!, $title: String!, $message: String!, $investmentId: ID) {
    updateFundPost(id: $id, title: $title, message: $message, investmentId: $investmentId) {
      id
      status
      title
      message
      investment {
        id
        deal {
          id
          companyInformation {
            id
            company {
              name
            }
          }
        }
      }
      createdAt
    }
  }
`);

const UpdateFundPost: FC<{
  id: string;
  title: string;
  message: string;
  createdAt: string;
  investment?:
    | {
        id: string;
        deal: {
          id: string;
          status: DealStatusEnumType;
        };
      }
    | null
    | undefined;
  isBeingEdited?: (boolean) => void;
}> = ({ id, title, message, createdAt, investment, isBeingEdited }) => {
  const [updatePost, { loading, error }] = useMutation(UPDATE_FUND_POST_MUTATION, {
    refetchQueries: ['getFundPost'],
  });

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        isInReadOnlyMode={readOnlyMode => isBeingEdited && isBeingEdited(!readOnlyMode)}
        onSubmit={data => {
          updatePost({
            variables: {
              id,
              title: data.title,
              message: data.content,
              investmentId: investment ? investment.id : undefined,
            },
          });
        }}
        submitButtonLabel="Save"
      >
        <FormInput
          fullWidth
          type="text"
          fieldName="title"
          label="Title"
          defaultValue={title}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="custom"
          fieldName="content"
          label="Content"
          defaultValue={message}
          customInput={EditorFormInput}
          validators={{
            required: true,
          }}
        />
      </FormPanelWithReadMode>
    </>
  );
};

export default UpdateFundPost;
