import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const COMPANY_WISHLISTED_BY_QUERY = gql(`
  query getCompanyWishlistedBy($id: ID!) {
    company(id: $id) {
      id
      wishlistedBy {
        nodes {
          id
          addedBy {
            id
            fullName
          }
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const CompanyWishlisted: FC = () => {
  const navigate = useNavigate();

  const { companyId } = useParams<{ companyId: string }>() as {
    companyId: string;
  };

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(
    COMPANY_WISHLISTED_BY_QUERY,
    {
      variables: {
        id: companyId,
      },
    }
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const users = data.company.wishlistedBy.nodes;
  const pageInfo = data.company.wishlistedBy.pageInfo;

  return (
    <>
      <DataTable
        data={users}
        onClick={wishlistCompany => navigate(`/users/${wishlistCompany.addedBy.id}/wishlisted`)}
        columns={[
          {
            label: 'Name',
            fieldName: 'addedBy.fullName',
          },
          {
            label: 'Added On',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default CompanyWishlisted;
