import { DealExitTypeEnumType } from '__generated__/graphql';

const dealExitTypeToReadable = (
  type: DealExitTypeEnumType
): {
  label: string;
  color: string;
} => {
  switch (type) {
    case DealExitTypeEnumType.Acquisition:
      return {
        label: 'Acquisition',
        color: 'green',
      };
    case DealExitTypeEnumType.LiquidationProceeds:
      return {
        label: 'Liquidation Proceeds',
        color: 'yellow',
      };
    case DealExitTypeEnumType.ShareSwap:
      return {
        label: 'Share Swap',
        color: 'blue',
      };
    case DealExitTypeEnumType.ShutDown:
      return {
        label: 'Shutdown',
        color: 'red',
      };
    case DealExitTypeEnumType.BuybackProceeds:
      return {
        label: 'Buyback Proceeds',
        color: 'purple',
      };
    case DealExitTypeEnumType.SecondarySale:
      return {
        label: 'Secondary Sale',
        color: 'orange',
      };
    case DealExitTypeEnumType.PartialSale:
      return {
        label: 'Partial Sale',
        color: 'pink',
      };
    case DealExitTypeEnumType.ReturnOfCapital:
      return {
        label: 'Return of Capital',
        color: 'gray',
      };
    case DealExitTypeEnumType.IncomeFromOtherSources:
      return {
        label: 'Income from Other Sources',
        color: 'gray',
      };
    case DealExitTypeEnumType.Flip:
      return {
        label: 'Flip',
        color: 'gray',
      };
    case DealExitTypeEnumType.BonusShares:
      return {
        label: 'Bonus Shares',
        color: 'gray',
      };
    case DealExitTypeEnumType.InterestIncome:
      return {
        label: 'Interest Income',
        color: 'gray',
      };
    case DealExitTypeEnumType.DividendIncome:
      return {
        label: 'Dividend Income',
        color: 'gray',
      };
    default:
      return {
        label: 'Unknown',
        color: 'gray',
      };
  }
};

export default dealExitTypeToReadable;
