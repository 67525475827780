import { gql, useMutation } from '@apollo/client';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const SET_SORT_ORDER_FOR_DISCOVER = gql`
  mutation SetSortOrderForDiscoverForSyndicate($syndicateId: ID!, $sortOrder: Int) {
    setSortOrderForDiscoverForSyndicate(syndicateId: $syndicateId, sortOrder: $sortOrder) {
      id
      sortOrderForDiscover
    }
  }
`;

interface SortOrderInputProps {
  syndicateId: string;
  sortOrder?: number | null;
}

const UpdateSortOrderForDiscover = ({ syndicateId, sortOrder }: SortOrderInputProps) => {
  const [setSortOrderMutation, { loading, error }] = useMutation(SET_SORT_ORDER_FOR_DISCOVER);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        setSortOrderMutation({
          variables: {
            syndicateId,
            sortOrder: data.sortOrder ? data.sortOrder : undefined,
          },
        });
      }}
      title="Sort Order on Discover page"
    >
      <FormInput
        type="number"
        fieldName="sortOrder"
        label="Sort Order on Discover page"
        defaultValue={sortOrder}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateSortOrderForDiscover;
