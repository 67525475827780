import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const USER_WISHLISTED_BY_QUERY = gql(`
  query getUserWishlistedBy($id: ID!) {
    user(id: $id) {
      id
      companiesWishlisted {
        nodes {
          id
          company {
            id
            name
          }
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const UserWishlisted: FC = () => {
  const navigate = useNavigate();

  const { userId } = useParams<{ userId: string }>() as {
    userId: string;
  };

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(
    USER_WISHLISTED_BY_QUERY,
    {
      variables: {
        id: userId,
      },
    }
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const companies = data.user.companiesWishlisted.nodes;
  const pageInfo = data.user.companiesWishlisted.pageInfo;

  return (
    <>
      <DataTable
        data={companies}
        onClick={wishlistCompany => navigate(`/companies/${wishlistCompany.company.id}/wishlisted`)}
        columns={[
          {
            label: 'Name',
            fieldName: 'company.name',
          },
          {
            label: 'Added On',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default UserWishlisted;
