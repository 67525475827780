import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import companyIcon from 'media/icons/company.svg';

const InvestmentWishlists: FC = () => {
  const tabs = [
    {
      key: 'tagged',
      label: `Tagged`,
      description: 'Tagged Wishlist Companies',
      icon: companyIcon,
      route: `/investment-wishlists`,
      exact: true,
    },
    {
      key: 'untagged',
      label: `Untagged`,
      description: 'Untagged Wishlist Companies',
      icon: companyIcon,
      route: `/investment-wishlists/untagged`,
    },
  ];

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Investment Wishlists"
        subTitle="Companies investors are interested in investing in"
      />
      <SidebarLayoutContent secondaryTabs={tabs}>
        <Outlet />
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default InvestmentWishlists;
