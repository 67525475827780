import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import RemoveManagerFromFundButton from './RemoveManagerFromFundButton';

const FUND_MANAGER_QUERY = gql(`
  query getFundManager($id: ID!) {
    fundManager(id: $id) {
      id
      role
      user {
        id
        name
        email
      }
      createdAt
    }
  }
`);

const UPDATE_FUND_MANAGER_ROLE_MUTATION = gql(`
  mutation updateFundManagerRole($id: ID!, $role: FundManagerRoleEnumType!) {
    updateFundManagerRole(id: $id, role: $role) {
      id
      role
    }
  }
`);

const FundManagerDialog: FC<{
  fundManagerId: string;
  onClose: () => void;
}> = ({ fundManagerId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(FUND_MANAGER_QUERY, {
    variables: {
      id: fundManagerId,
    },
  });

  const [updateFundManagerRole, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_FUND_MANAGER_ROLE_MUTATION
  );

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const fundManager = data.fundManager;

    return (
      <>
        <div>
          <RemoveManagerFromFundButton fundManagerId={fundManagerId} onClose={onClose} />
        </div>
        <DetailsPanel
          title="Manager Details"
          data={[
            {
              label: 'Name',
              value: fundManager.user.name,
            },
            {
              label: 'Email',
              value: fundManager.user.email,
            },
            {
              label: 'Joined At',
              value: fundManager.createdAt,
              type: 'DATETIME',
            },
          ]}
        />
        <FormPanelWithReadMode
          loading={updateLoading}
          error={updateError}
          title="Update Role"
          onSubmit={values =>
            updateFundManagerRole({ variables: { id: fundManagerId, role: values.role } })
          }
        >
          <FormInput
            type="select"
            fieldName="role"
            label="Role"
            defaultValue={'ADMIN'}
            options={[
              { label: 'Admin', value: 'ADMIN' },
              { label: 'Owner', value: 'OWNER' },
            ]}
          />
        </FormPanelWithReadMode>
      </>
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Fund Manager</DialogTitle>
        {renderContent()}
      </DialogPanel>
    </Dialog>
  );
};

export default FundManagerDialog;
