import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  DealStatusEnumType,
  FundPostStatusEnumType,
  FundStatusEnumType,
  InvestmentEntityStatusEnumType,
  PostStatusEnumType,
  SyndicateStatusEnumType,
  UserStatusEnumType,
} from '__generated__/graphql';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import bankIcon from 'media/icons/bank.svg';
import dealIcon from 'media/icons/deal.svg';
import postIcon from 'media/icons/post.svg';
import syndicateIcon from 'media/icons/syndicate.svg';

const APPROVAL_STATISTICS_QUERY = gql(`
  query getApprovalStatistics {
    deals {
      pageInfo {
        cursor
      }
      statistics {
        dealStatisticsBreakdownByDealStatus {
          status
          numberOfDeals
        }
      }
    }
    funds {
      pageInfo {
        cursor
      }
      statistics {
        fundStatisticsBreakdownByFundStatus {
          status
          numberOfFunds
        }
      }
    }
    syndicates {
      pageInfo {
        cursor
      }
      statistics {
        syndicateStatisticsBreakdownBySyndicateStatus {
          status
          numberOfSyndicates
        }
      }
    }
    investmentEntities {
      pageInfo {
        cursor
      }
      statistics {
        investmentEntityBreakdownByInvestmentEntityStatus {
          status
          numberOfInvestmentEntities
        }
      }
    }
    users {
      pageInfo {
        cursor
      }
      statistics {
        userStatisticsBreakdownByUserStatus {
          status
          numberOfUsers
        }
      }
    }
    posts {
      pageInfo {
        cursor
      }
      statistics {
        postStatisticsBreakdownByPostStatus {
          status
          numberOfPosts
        }
      }
    }
    fundPosts {
      pageInfo {
        cursor
      }
      statistics {
        fundPostStatisticsBreakdownByPostStatus {
          status
          numberOfPosts
        }
      }
    }
  }
`);

const Approvals: FC = () => {
  const { loading, error, data, refetch } = useQuery(APPROVAL_STATISTICS_QUERY);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const numberOfUnderReviewInvestmentEntities =
      data.investmentEntities.statistics.investmentEntityBreakdownByInvestmentEntityStatus.find(
        status => status.status === InvestmentEntityStatusEnumType.UnderReview
      )?.numberOfInvestmentEntities;

    const numberOfUnderReviewSyndicates =
      data.syndicates.statistics.syndicateStatisticsBreakdownBySyndicateStatus.find(
        status => status.status === SyndicateStatusEnumType.UnderReview
      )?.numberOfSyndicates;

    const numberOfUnderReviewDeals = data.deals.statistics.dealStatisticsBreakdownByDealStatus.find(
      status => status.status === DealStatusEnumType.UnderOpsReview
    )?.numberOfDeals;

    const numberOfUnderReviewFunds = data.funds.statistics.fundStatisticsBreakdownByFundStatus.find(
      status => status.status === FundStatusEnumType.UnderReview
    )?.numberOfFunds;

    const numberOfUnderReviewUsers = data.users.statistics.userStatisticsBreakdownByUserStatus.find(
      status => status.status === UserStatusEnumType.UnderReview
    )?.numberOfUsers;

    const numberOfUnderReviewRuvUsers =
      data.users.statistics.userStatisticsBreakdownByUserStatus.find(
        status => status.status === UserStatusEnumType.RuvReview
      )?.numberOfUsers;
    const numberOfUnderReviewPosts = data.posts.statistics.postStatisticsBreakdownByPostStatus.find(
      status => status.status === PostStatusEnumType.UnderReview
    )?.numberOfPosts;

    const numberOfUnderReviewFundPosts =
      data.fundPosts.statistics.fundPostStatisticsBreakdownByPostStatus.find(
        status => status.status === FundPostStatusEnumType.UnderReview
      )?.numberOfPosts;

    const tabs = [
      {
        key: 'investment-entities',
        label: `Investment Entities (${numberOfUnderReviewInvestmentEntities})`,
        description: 'Entities',
        icon: bankIcon,
        route: `/approvals`,
        exact: true,
      },
      {
        key: 'users',
        label: `Users (${numberOfUnderReviewUsers})`,
        description: 'Users',
        icon: bankIcon,
        route: `/approvals/users`,
      },
      {
        key: 'ruv-users',
        label: `RUV Users (${numberOfUnderReviewRuvUsers})`,
        description: 'RUV Users',
        icon: bankIcon,
        route: `/approvals/ruv-users`,
      },
      {
        key: 'deals',
        label: `Deals (${numberOfUnderReviewDeals})`,
        description: 'Deals',
        icon: dealIcon,
        route: `/approvals/deals`,
      },
      {
        key: 'syndicates',
        label: `Syndicates (${numberOfUnderReviewSyndicates})`,
        description: 'Syndicates',
        icon: syndicateIcon,
        route: `/approvals/syndicates`,
      },
      {
        key: 'funds',
        label: `Funds (${numberOfUnderReviewFunds})`,
        description: 'Funds',
        icon: syndicateIcon,
        route: `/approvals/funds`,
      },
      {
        key: 'posts',
        label: `Posts (${numberOfUnderReviewPosts})`,
        description: 'Posts',
        icon: postIcon,
        route: `/approvals/posts`,
      },
      {
        key: 'fund-posts',
        label: `Fund Posts (${numberOfUnderReviewFundPosts})`,
        description: 'Fund Posts',
        icon: postIcon,
        route: `/approvals/fund-posts`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation title="Approvals" subTitle="Review and resolve" />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default Approvals;
