export default function statusEnumToReadable(status: string): {
  label: string;
  color: string;
} {
  switch (status) {
    case 'ACTIVE':
      return {
        label: 'Active',
        color: 'green',
      };
    case 'UNDER_REVIEW':
      return {
        label: 'Under Review',
        color: 'amber',
      };
    case 'UNDER_OPS_REVIEW':
      return {
        label: 'Under Review',
        color: 'amber',
      };
    case 'INACTIVE':
      return {
        label: 'Inactive',
        color: 'gray',
      };
    case 'CANCELLED':
      return {
        label: 'Cancelled',
        color: 'red',
      };
    case 'CLOSED':
      return {
        label: 'Closed',
        color: 'yellow',
      };
    case 'DELETED':
      return {
        label: 'Deleted',
        color: 'red',
      };
    case 'CREATED':
      return {
        label: 'Created',
        color: 'indigo',
      };
    case 'COMPLETED':
      return {
        label: 'Completed',
        color: 'purple',
      };
    case 'DRAFT':
      return {
        label: 'Draft',
        color: 'gray',
      };
    case 'RAISING':
      return {
        label: 'Raising',
        color: 'green',
      };
    case 'COMMITTED':
      return {
        label: 'Committed',
        color: 'green',
      };
    case 'TRANSFERRED':
      return {
        label: 'Transferred',
        color: 'purple',
      };
    case 'EXECUTED':
      return {
        label: 'Executed',
        color: 'purple',
      };
    case 'WIRED':
      return {
        label: 'Wired',
        color: 'purple',
      };
    case 'UNDER_LEGAL_REVIEW':
      return {
        label: 'Legal Review',
        color: 'amber',
      };
    case 'POOLING':
      return {
        label: 'Pooling',
        color: 'lime',
      };
    case 'FUNDED':
      return {
        label: 'Funded',
        color: 'lime',
      };
    case 'EXITED':
      return {
        label: 'Exited',
        color: 'purple',
      };
    case 'PARTIALLY_EXITED':
      return {
        label: 'Partially Exited',
        color: 'purple',
      };
    case 'PARTIALLY_LIQUIDATED':
      return {
        label: 'Partially Liquidated',
        color: 'purple',
      };
    case 'REJECTED':
      return {
        label: 'Rejected',
        color: 'red',
      };
    case 'PENDING_EMAIL_VERIFICATION':
      return {
        label: 'Pending Email Verification',
        color: 'amber',
      };
    case 'PENDING_INVESTMENT_ENTITY_CREATION':
      return {
        label: 'Pending Investment Entity Creation',
        color: 'amber',
      };
    default:
      return {
        label: status,
        color: 'gray',
      };
  }
}
