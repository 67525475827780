import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon, EyeIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import DeleteFundPostButton from './DeleteFundPostButton';
import PublishFundPostButton from './PublishFundPostButton';
import UpdateFundPost from './UpdateFundPost';
import UpdateFundPostStatus from './UpdateFundPostStatus';

const FUND_POST_QUERY = gql(`
  query getFundPost($id: ID!) {
    fundPost(id: $id) {
      id
      title
      message
      status
      createdAt
      updatedAt
      permissions {
        canUpdatePostStatus
      }
      investment {
        id
        deal {
          id
          status
        }
      }
      fund {
        id
        fundClosings {
          pageInfo {
            totalCount
          }
        }
      }
      attachments {
        url
        name
      }
    }
  }
`);

const FundPostPage: FC = () => {
  const navigate = useNavigate();
  const { fundPostId } = useParams<{ fundPostId: string }>() as { fundPostId: string };
  const [showActionButtons, setShowActionButtons] = useState(true);

  const { loading, error, data, refetch } = useQuery(FUND_POST_QUERY, {
    variables: {
      id: fundPostId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const post = data.fundPost;

    return (
      <>
        <SidebarLayoutNavigation
          title="Fund Post"
          subTitle="Manage Fund Post"
          onBackButtonClick={() => navigate(-1)}
          actionButtons={[
            <NavLink
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 aria-disabled:cursor-not-allowed"
              title="Go to Deal Page"
              to={`/deals/${post.investment?.deal.id}`}
              target="_blank"
              aria-disabled={!post.investment}
              onClick={e => {
                if (!post.investment) e.preventDefault();
              }}
            >
              {post.investment ? (
                <>
                  <span className="mr-1 text-sm">View Deal</span>
                  <BuildingOffice2Icon className="h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <span className="mr-1 text-sm text-gray-500">No deal set</span>
              )}
            </NavLink>,
            <NavLink
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Go to Syndicate Page"
              target="_blank"
              to={`/funds/${post.fund.id}`}
            >
              <span className="mr-1 text-sm">View Fund</span>
              <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
          ]}
        />
        <SidebarLayoutContent>
          <UpdateFundPost
            id={post.id}
            title={post.title}
            message={post.message}
            createdAt={post.createdAt}
            investment={post.investment}
            isBeingEdited={isBeingEdited => setShowActionButtons(isBeingEdited)}
          />
          <UpdateFundPostStatus
            id={post.id}
            status={post.status}
            canUpdatePostStatus={post.permissions.canUpdatePostStatus}
            isBeingEdited={isBeingEdited => setShowActionButtons(isBeingEdited)}
          />
          {post.attachments && post.attachments.length > 0 && (
            <div className="mt-4">
              <h3 className="text-sm font-medium text-gray-900">Attachments</h3>
              <ul className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200">
                {post.attachments.map(attachment => (
                  <li
                    key={attachment.url}
                    className="flex items-center justify-between py-2 px-4 hover:bg-gray-50"
                  >
                    <div className="flex items-center">
                      <span className="truncate text-sm text-gray-500">{attachment.name}</span>
                    </div>
                    <a
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-500"
                    >
                      View
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {showActionButtons ? (
            <div className="grid grid-flow-col gap-4 mt-8">
              <DeleteFundPostButton id={post.id} />
              <PublishFundPostButton
                id={post.id}
                canPublishPost={post.permissions.canUpdatePostStatus}
                totalClosingsTargeted={post.fund.fundClosings.pageInfo?.totalCount}
              />
            </div>
          ) : null}
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default FundPostPage;
