import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_MANAGER_FROM_FUND_MUTATION = gql(`
  mutation RemoveManagerFromFund($fundManagerId: ID!) {
    removeManagerFromFund(fundManagerId: $fundManagerId) {
      id
      fund {
        id
      }
    }
  }
`);

const RemoveManagerFromFundButton: FC<{
  fundManagerId: string;
  onClose: () => void;
}> = ({ fundManagerId, onClose }) => {
  const [removeManagerFromFund, { loading, error }] = useMutation(
    REMOVE_MANAGER_FROM_FUND_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        const { removeManagerFromFund } = data;
        cache.modify({
          id: `FundType:${removeManagerFromFund.fund.id}`,
          fields: {
            fundManagers(existingManagersRef, { readField }) {
              return existingManagersRef.filter(
                managerRef => fundManagerId !== readField('id', managerRef)
              );
            },
          },
        });
      },
      onCompleted() {
        onClose();
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={() => removeManagerFromFund({ variables: { fundManagerId } })}
      loading={loading}
      error={error}
      description="This will delete this user from this fund."
    >
      Remove from Fund
    </ConfirmButton>
  );
};

export default RemoveManagerFromFundButton;
