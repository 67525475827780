import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentWishlistCompanyAggregatesEnumType } from '__generated__/graphql';
import { FC, useEffect } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import useQueryParams from 'utils/hooks/use-query-params';
import useScroll from 'utils/hooks/use-scroll';

const UNTAGGED_WISHLIST_COMPANIES_QUERY = gql(`
  query UntaggedWishlistCompanies($filters: InvestmentWishlistCompanyAggregatesFiltersType, $limit: Int, $page: Int, $skip: Boolean) {
    wishlistCompanies(filters: $filters, limit: $limit, page: $page, skip: $skip  ) {
      nodes {
        count
        name
        website
        investmentWishlists {
          id
          addedBy {
            fullName
          }
        }
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`);

const UntaggedWishlistCompaniesPage: FC = () => {
  const { onScroll, onComponentUnmount, onDataLoad } = useScroll({
    pageIdentifier: 'untaggedInvestmentWishlistCompaniesScrollPosition',
  });
  const { page, onLoadMore } = useQueryParams();

  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(
    UNTAGGED_WISHLIST_COMPANIES_QUERY,
    {
      variables: {
        limit: page * 15,
        page,
        skip: false,
        filters: {
          type: InvestmentWishlistCompanyAggregatesEnumType.Untagged,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (data?.wishlistCompanies.nodes.length) onDataLoad();
  }, [data, onDataLoad]);

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const wishlistCompanies = data.wishlistCompanies.nodes.map(wishlistCompany => ({
    ...wishlistCompany,
    investmentWishlists: wishlistCompany?.investmentWishlists?.[0],
  }));
  const pageInfo = data.wishlistCompanies.pageInfo;

  return (
    <>
      <DataTable
        data={wishlistCompanies}
        onUnmount={onComponentUnmount}
        onScroll={onScroll}
        columns={[
          {
            label: 'Company',
            fieldName: 'name',
          },
          {
            label: 'Website',
            fieldName: 'website',
            type: 'STRING',
          },
          {
            label: 'Added by',
            fieldName: 'investmentWishlists.addedBy.fullName',
            type: 'STRING',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() => {
          onLoadMore();
          fetchMore({
            variables: {
              page: page + 1,
              limit: 15,
              skip: true,
            },
          });
        }}
      />
    </>
  );
};

export default UntaggedWishlistCompaniesPage;
