import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const PUBLISH_FUND_POST_MUTATION = gql(`
  mutation PublishFundPost($id: ID!) {
    publishFundPost(id: $id) {
      id
      status
    }
  }
`);

const PublishFundPostButton: FC<{
  id: string;
  canPublishPost: boolean;
  totalClosingsTargeted?: number;
}> = ({ id, canPublishPost, totalClosingsTargeted }) => {
  const [publishFundPost, { loading, error }] = useMutation(PUBLISH_FUND_POST_MUTATION);

  return (
    <ConfirmButton
      onConfirm={() => publishFundPost({ variables: { id } })}
      loading={loading}
      error={error}
      disabled={!canPublishPost}
      description={`Publishing this post will trigger an email to ${
        totalClosingsTargeted || 'all'
      } recipient(s). This cannot be undone.`}
    >
      Publish
    </ConfirmButton>
  );
};

export default PublishFundPostButton;
