import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_BANK_ACCOUNT_MUTATION = gql(`
  mutation UpdateBankAccount(
    $id: ID!
    $accountNumber: String!
    $accountHolderName: String!
    $ifscCode: String!
    $bankName: String!
    $accountType: BankAccountTypeEnumType!
  ) {
    updateBankAccount(
      id: $id
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
      ifscCode: $ifscCode
      bankName: $bankName
      accountType: $accountType
    ) {
      id
      accountNumber
      accountHolderName
      ifscCode
      bankName
      accountType
    }
  }
`);

const REMOVE_BANK_ACCOUNT_MUTATION = gql(`
  mutation RemoveBankAccountFromInvestorApp($bankAccountId: ID!) {
    removeBankAccount(bankAccountId: $bankAccountId) {
      id
    }
  }
`);

const UpdateBankAccount: FC<{
  investmentEntityId: string;
  id: string;
  accountNumber: string;
  accountHolderName: string;
  ifscCode: string;
  bankName: string;
  accountType: string;
}> = ({
  investmentEntityId,
  id,
  accountNumber,
  accountHolderName,
  ifscCode,
  bankName,
  accountType,
}) => {
  const [updateBankAccount, { loading, error }] = useMutation(UPDATE_BANK_ACCOUNT_MUTATION);

  const [removeBankAccount, { loading: removeAccountLoading, error: removeAccountError }] =
    useMutation(REMOVE_BANK_ACCOUNT_MUTATION, {
      variables: {
        bankAccountId: id,
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: `InvestmentEntityType:${investmentEntityId}`,
          fields: {
            bankAccounts(existingAccountRefs, { readField }) {
              return existingAccountRefs.filter(accountRef => id !== readField('id', accountRef));
            },
          },
        });
      },
    });

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        onSubmit={data => {
          updateBankAccount({
            variables: {
              id,
              accountNumber: data.accountNumber,
              accountHolderName: data.accountHolderName,
              ifscCode: data.ifscCode,
              bankName: data.bankName,
              accountType: data.accountType,
            },
          });
        }}
      >
        <FormInput
          type="bank_account_number"
          fieldName="accountNumber"
          label="Account Number"
          defaultValue={accountNumber}
        />
        <FormInput
          type="text"
          fieldName="accountHolderName"
          label="Account Holder Name"
          defaultValue={accountHolderName}
        />
        <FormInput type="text" fieldName="ifscCode" label="IFSC Code" defaultValue={ifscCode} />
        <FormInput type="text" fieldName="bankName" label="Bank Name" defaultValue={bankName} />
        <FormInput
          type="radio-group"
          fieldName="accountType"
          label="Account Type"
          defaultValue={accountType}
          options={[
            { label: 'Savings', value: 'SAVINGS' },
            { label: 'Current', value: 'CURRENT' },
          ]}
        />
      </FormPanelWithReadMode>
      <ConfirmButton
        loading={removeAccountLoading}
        error={removeAccountError}
        title="Delete Bank Account"
        description="Are you sure you want to delete this bank account?"
        confirmButtonLabel="Delete"
        onConfirm={() => removeBankAccount()}
      >
        Delete bank account
      </ConfirmButton>
    </>
  );
};

export default UpdateBankAccount;
