import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import AddManagerToFundDialogButton from './AddManagerToFundDialogButton';
import FundManagerDialog from './FundManagerDialog';

const MANAGERS_QUERY = gql(`
  query fetchManagersByFund($fundId: ID!) {
    fund(id: $fundId) {
      id
      fundManagers {
        id
        role
        user {
          id
          name
          email
        }
        createdAt
      }
    }
  }
`);

const FundManagers: FC = () => {
  const [fundManagerOnDisplay, setFundManagerOnDisplay] = useState('');
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(MANAGERS_QUERY, {
    variables: {
      fundId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fund = data.fund;
  const fundManagers = fund.fundManagers;

  return (
    <>
      <AddManagerToFundDialogButton fundId={fund.id} />
      {fundManagerOnDisplay && (
        <FundManagerDialog
          fundManagerId={fundManagerOnDisplay}
          onClose={() => setFundManagerOnDisplay('')}
        />
      )}
      <DataTable
        data={fundManagers}
        searchFields={['user.name']}
        onClick={manager => setFundManagerOnDisplay(manager.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Email',
            fieldName: 'user.email',
          },
          {
            label: 'Role',
            fieldName: 'role',
          },
          {
            label: 'Joined At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
      />
    </>
  );
};

export default FundManagers;
