import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, ReactNode, useState } from 'react';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';
import Switch from 'primitives/Switch';
import TextField from 'primitives/TextField';

import ErrorMessage from 'components/ErrorMessage';

import distributionStatusToReadable from 'utils/enums/distribution-status-to-readable';

const Td: FC<{ children: ReactNode }> = ({ children }) => (
  <td className="p-2 border border-gray-200">{children}</td>
);

const Th: FC<{ children: ReactNode }> = ({ children }) => (
  <th className="bg-gray-50 p-2 text-left border border-gray-200 text-sm font-semibold">
    {children}
  </th>
);

const UPDATE_DISTRIBUTION_MUTATION = gql(`
  mutation updateDistribution($id: ID!, $status: DistributionStatusEnumType, $isNri: Boolean, $isCarryRecipient: Boolean, $investedAmount: Float, $proceedsFromExit: Float, $distributedAmount: Float, $carryAmount: Float, $taxDeduction: Float, $otherDeductions: Float, $capitalGainOrLoss: Float, $indexedCapitalGainOrLoss: Float, $isPartial: Boolean, $distributedAt: String) {
    updateDistribution(id: $id,
      status: $status,
      isNri: $isNri,
      isCarryRecipient: $isCarryRecipient,
      investedAmount: $investedAmount,
      proceedsFromExit: $proceedsFromExit,
      distributedAmount: $distributedAmount,
      carryAmount: $carryAmount,
      taxDeduction: $taxDeduction,
      otherDeductions: $otherDeductions,
      capitalGainOrLoss: $capitalGainOrLoss,
      indexedCapitalGainOrLoss: $indexedCapitalGainOrLoss,
      isPartial: $isPartial,
      distributedAt: $distributedAt
    ) {
      id
      status
      isNri
      isCarryRecipient
      isPartial
      investedAmount
      proceedsFromExit
      distributedAmount
      distributedAt
      carryAmount
      taxDeduction
      otherDeductions
      capitalGainOrLoss
      indexedCapitalGainOrLoss
    }
  }
`);

const UPDATE_FUND_DISTRIBUTION_MUTATION = gql(`
  mutation updateFundDistribution($id: ID!, $status: DistributionStatusEnumType, $isNri: Boolean, $isCarryRecipient: Boolean, $investedAmount: Float, $proceedsFromExit: Float, $distributedAmount: Float, $carryAmount: Float, $taxDeduction: Float, $otherDeductions: Float, $capitalGainOrLoss: Float, $indexedCapitalGainOrLoss: Float, $isPartial: Boolean, $distributedAt: String) {
    updateFundDistribution(id: $id,
      status: $status,
      isNri: $isNri,
      isCarryRecipient: $isCarryRecipient,
      investedAmount: $investedAmount,
      proceedsFromExit: $proceedsFromExit,
      distributedAmount: $distributedAmount,
      carryAmount: $carryAmount,
      taxDeduction: $taxDeduction,
      otherDeductions: $otherDeductions,
      capitalGainOrLoss: $capitalGainOrLoss,
      indexedCapitalGainOrLoss: $indexedCapitalGainOrLoss,
      isPartial: $isPartial,
      distributedAt: $distributedAt
    ) {
      id
      status
      isNri
      isCarryRecipient
      isPartial
      investedAmount
      proceedsFromExit
      distributedAmount
      distributedAt
      carryAmount
      taxDeduction
      otherDeductions
      capitalGainOrLoss
      indexedCapitalGainOrLoss
    }
  }
`);

const Table: FC<{ children: ReactNode }> = ({ children }) => (
  <table className="w-full border-collapse my-4 rounded-lg overflow-hidden">{children}</table>
);

const TableContainer: FC<{ children: ReactNode; loading?: boolean }> = ({ children, loading }) => (
  <div className="relative overflow-x-auto pr-4 pb-4">
    {loading && (
      <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-10">
        <LoadingIndicator />
      </div>
    )}
    <div className={loading ? 'pointer-events-none' : ''}>{children}</div>
  </div>
);

const TableHeader: FC<{
  hasChanges: boolean;
  onSave: () => void;
  onDiscard: () => void;
  loading: boolean;
}> = ({ hasChanges, onSave, onDiscard, loading }) => {
  if (!hasChanges) return null;

  return (
    <div className="flex justify-end gap-2 mb-4">
      <button
        onClick={onDiscard}
        disabled={loading}
        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Discard Changes
      </button>
      <button
        onClick={onSave}
        disabled={loading}
        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Save Changes
      </button>
    </div>
  );
};

type DistributionType = 'regular' | 'fund';

interface DistributionsTableProps {
  distributions: any[];
  onUpdate: () => void;
  type: DistributionType;
}

const DistributionsTable: FC<DistributionsTableProps> = ({ distributions, onUpdate, type }) => {
  const [editedData, setEditedData] = useState({});
  const [updateDistribution, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_DISTRIBUTION_MUTATION
  );
  const [updateFundDistribution, { loading: updateFundLoading, error: updateFundError }] =
    useMutation(UPDATE_FUND_DISTRIBUTION_MUTATION);

  const handleChange = (id: string, field: string, value: any) => {
    setEditedData(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        Object.entries(editedData).map(([id, changes]) => {
          const parsedChanges = {
            // @ts-ignore
            ...changes,
            // @ts-ignore
            investedAmount: changes.investedAmount ? parseFloat(changes.investedAmount) : undefined,
            // @ts-ignore
            proceedsFromExit: changes.proceedsFromExit
              ? // @ts-ignore
                parseFloat(changes.proceedsFromExit)
              : undefined,
            // @ts-ignore
            carryAmount: changes.carryAmount ? parseFloat(changes.carryAmount) : undefined,
            // @ts-ignore
            taxDeduction: changes.taxDeduction ? parseFloat(changes.taxDeduction) : undefined,
            // @ts-ignore
            otherDeductions: changes.otherDeductions
              ? // @ts-ignore
                parseFloat(changes.otherDeductions)
              : undefined,
            // @ts-ignore
            capitalGainOrLoss: changes.capitalGainOrLoss
              ? // @ts-ignore
                parseFloat(changes.capitalGainOrLoss)
              : undefined,
            // @ts-ignore
            indexedCapitalGainOrLoss: changes.indexedCapitalGainOrLoss
              ? // @ts-ignore
                parseFloat(changes.indexedCapitalGainOrLoss)
              : undefined,
            // @ts-ignore
            distributedAmount: changes.distributedAmount
              ? // @ts-ignore
                parseFloat(changes.distributedAmount)
              : undefined,
            // @ts-ignore
            isPartial: changes.isPartial,
            // @ts-ignore
            distributedAt: changes.distributedAt,
          };

          if (type === 'fund') {
            return updateFundDistribution({
              variables: {
                id,
                ...parsedChanges,
              },
            });
          }

          return updateDistribution({
            variables: {
              id,
              ...parsedChanges,
            },
          });
        })
      );

      setEditedData({});
      onUpdate();
    } catch (error) {
      // Error handling is already done via the error state from useMutation
    }
  };

  const handleDiscard = () => {
    setEditedData({});
  };

  return (
    <div>
      {(updateError || updateFundError) && <ErrorMessage error={updateError || updateFundError} />}
      <TableHeader
        hasChanges={Object.keys(editedData).length > 0}
        onSave={handleSave}
        onDiscard={handleDiscard}
        loading={updateLoading || updateFundLoading}
      />
      <TableContainer loading={updateLoading || updateFundLoading}>
        <Table>
          <thead>
            <tr>
              <Th>#</Th>
              <Th>Investment Entity</Th>
              <Th>Is NRI</Th>
              <Th>Is Carry Recipient</Th>
              <Th>Invested Amount</Th>
              <Th>Proceeds from Exit</Th>
              <Th>Carry Amount</Th>
              <Th>Capital Gain/Loss</Th>
              <Th>Indexed Capital Gain/Loss</Th>
              <Th>Tax Deduction</Th>
              <Th>Other Deductions</Th>
              <Th>Total Amount Before Tax</Th>
              <Th>Distributed Amount</Th>
              <Th>Status</Th>
              <Th>Distributed At</Th>
              <Th>Distribution Document</Th>
            </tr>
          </thead>
          <tbody>
            {distributions.map((distribution, index) => {
              const isEdited = !!editedData[distribution.id];
              const currentData = isEdited
                ? { ...distribution, ...editedData[distribution.id] }
                : distribution;

              return (
                <tr key={distribution.id} className={currentData.isNri ? 'bg-indigo-50' : ''}>
                  <Td>{index + 1}</Td>
                  <Td>{distribution.investmentEntity.name}</Td>
                  <Td>
                    <Switch
                      label=""
                      checked={currentData.isNri ?? false}
                      onChange={checked => handleChange(distribution.id, 'isNri', checked)}
                    />
                  </Td>
                  <Td>
                    <Switch
                      label=""
                      checked={currentData.isCarryRecipient ?? false}
                      onChange={checked =>
                        handleChange(distribution.id, 'isCarryRecipient', checked)
                      }
                    />
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.investedAmount}
                      onChange={e =>
                        handleChange(distribution.id, 'investedAmount', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.proceedsFromExit}
                      onChange={e =>
                        handleChange(distribution.id, 'proceedsFromExit', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <div className="flex flex-col">
                      <TextField
                        type="currency"
                        leadingIcon={true}
                        value={currentData.carryAmount}
                        onChange={e => handleChange(distribution.id, 'carryAmount', e.target.value)}
                      />
                      {currentData.carryAmount > 0 && (
                        <span className="text-xs text-gray-500 mt-1">
                          {currentData.isCarryRecipient ? 'earned' : 'paid'}
                        </span>
                      )}
                    </div>
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.capitalGainOrLoss}
                      onChange={e =>
                        handleChange(distribution.id, 'capitalGainOrLoss', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.indexedCapitalGainOrLoss}
                      onChange={e =>
                        handleChange(distribution.id, 'indexedCapitalGainOrLoss', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.taxDeduction}
                      onChange={e => handleChange(distribution.id, 'taxDeduction', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.otherDeductions}
                      onChange={e =>
                        handleChange(distribution.id, 'otherDeductions', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    <div className="text-gray-700 min-w-44">
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        (currentData.investedAmount || 0) + (currentData.capitalGainOrLoss || 0)
                      )}
                    </div>
                  </Td>
                  <Td>
                    <TextField
                      type="currency"
                      leadingIcon={true}
                      value={currentData.distributedAmount}
                      onChange={e =>
                        handleChange(distribution.id, 'distributedAmount', e.target.value)
                      }
                    />
                  </Td>
                  <Td>
                    {(() => {
                      const { label, color } = distributionStatusToReadable(currentData.status);
                      return <Badge label={label} color={color} showIndicator={true} />;
                    })()}
                  </Td>
                  <Td>
                    {currentData.distributedAt
                      ? new Date(currentData.distributedAt).toLocaleDateString()
                      : '-'}
                  </Td>
                  <Td>
                    {currentData.distributionDocument ? (
                      <a
                        href={currentData.distributionDocument}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View Document
                      </a>
                    ) : (
                      '-'
                    )}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DistributionsTable;
